<template>
    <v-container class="pt-0">
        <v-row>
            <v-col cols="12" class="pa-0">
                <v-stepper v-model="getCarStep.step" class="elevation-0">
                    <template>
                        <v-stepper-header class="elevation-0 stepperHeader">
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[() => getCarError.stepOne.validation]"
                                editable
                                step="1"
                                >{{ $t('cars:carInformation') }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[() => getCarError.stepTwo.validation]"
                                editable
                                step="2"
                                >{{
                                    $t('cars:carExtraInformation')
                                }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content step="1">
                                <v-row>
                                    <v-col cols="12" md="12" class="pt-0">
                                        <Separator
                                            :text="$t('cars:carInformation')"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="4"
                                        ><v-select
                                            data-cy-cars="brandAndModel"
                                            :items="models"
                                            v-model="getCarModal.model"
                                            :error-messages="
                                                getCarError.stepOne.fields.model
                                            "
                                            :label="`${$t('cars:model')}*`"
                                            item-value="field"
                                        >
                                            <template v-slot:item="data">
                                                <span
                                                    v-if="
                                                        data.item
                                                            .backgroundColor
                                                    "
                                                >
                                                    <v-chip
                                                        class="mx-2"
                                                        :color="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                        small
                                                        label
                                                        :text-color="
                                                            data.item.textColor
                                                                ? data.item
                                                                      .textColor
                                                                : '#4d4d4d'
                                                        "
                                                    >
                                                        {{ data.item.field }}
                                                    </v-chip>
                                                </span>
                                                <span
                                                    class="truncate-list"
                                                    v-else
                                                >
                                                    {{ data.item.field }}
                                                </span>
                                            </template>
                                            <template v-slot:selection="data">
                                                <span
                                                    v-if="
                                                        data.item
                                                            .backgroundColor
                                                    "
                                                >
                                                    <v-chip
                                                        class="mx-2"
                                                        :color="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                        small
                                                        label
                                                        :text-color="
                                                            data.item.textColor
                                                                ? data.item
                                                                      .textColor
                                                                : '#4d4d4d'
                                                        "
                                                    >
                                                        {{ data.item.field }}
                                                    </v-chip>
                                                </span>
                                                <span
                                                    class="truncate-list"
                                                    v-else
                                                >
                                                    {{ data.item.field }}
                                                </span>
                                            </template>
                                        </v-select>
                                    </v-col>
                                    <v-col cols="4"
                                        ><v-text-field
                                            data-cy-cars="registrationNumber"
                                            v-model="
                                                getCarModal.registrationNumber
                                            "
                                            :error-messages="
                                                getCarError.stepOne.fields
                                                    .registrationNumber
                                            "
                                            :label="`${$t(
                                                'cars:registrationNumber'
                                            )}*`"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="4"
                                        ><v-select
                                            data-cy-cars="typeOfCar"
                                            :items="typeOfCar"
                                            v-model="getCarModal.typeOfCar"
                                            :label="$t('cars:typeOfCar')"
                                            item-value="field"
                                        >
                                            <template v-slot:item="data">
                                                <span
                                                    v-if="
                                                        data.item
                                                            .backgroundColor
                                                    "
                                                >
                                                    <v-chip
                                                        class="mx-2"
                                                        :color="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                        small
                                                        label
                                                        :text-color="
                                                            data.item.textColor
                                                                ? data.item
                                                                      .textColor
                                                                : '#4d4d4d'
                                                        "
                                                    >
                                                        {{ data.item.field }}
                                                    </v-chip>
                                                </span>
                                                <span
                                                    class="truncate-list"
                                                    v-else
                                                >
                                                    {{ data.item.field }}
                                                </span>
                                            </template>
                                            <template v-slot:selection="data">
                                                <span
                                                    v-if="
                                                        data.item
                                                            .backgroundColor
                                                    "
                                                >
                                                    <v-chip
                                                        class="mx-2"
                                                        :color="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                        small
                                                        label
                                                        :text-color="
                                                            data.item.textColor
                                                                ? data.item
                                                                      .textColor
                                                                : '#4d4d4d'
                                                        "
                                                    >
                                                        {{ data.item.field }}
                                                    </v-chip>
                                                </span>
                                                <span
                                                    class="truncate-list"
                                                    v-else
                                                >
                                                    {{ data.item.field }}
                                                </span>
                                            </template>
                                        </v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="4">
                                        <v-text-field
                                            data-cy-cars="registrationIDNumber"
                                            v-model="
                                                getCarModal.registrationIDNumber
                                            "
                                            :error-messages="
                                                getCarError.stepOne.fields
                                                    .registrationIDNumber
                                            "
                                            :label="`${$t(
                                                'cars:registrationIDNumber'
                                            )}*`"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field
                                            data-cy-cars="vin"
                                            v-model="getCarModal.vin"
                                            :error-messages="
                                                getCarError.stepOne.fields.vin
                                            "
                                            :label="`${$t('cars:vin')}*`"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="4" offset="8">
                                        <v-select
                                            data-cy-cars="driver"
                                            :items="drivers"
                                            v-model="getCarModal.driver"
                                            item-value="_id"
                                            :label="$t('cars:driver')"
                                        >
                                            <template
                                                v-slot:selection="{
                                                    item,
                                                }"
                                            >
                                                <span
                                                    >{{ item.name }}
                                                    {{ item.lastname }}</span
                                                >
                                            </template>
                                            <template v-slot:item="data">
                                                <span
                                                    >{{ data.item.name }}
                                                    {{
                                                        data.item.lastname
                                                    }}</span
                                                >
                                            </template>
                                        </v-select></v-col
                                    >
                                </v-row>
                            </v-stepper-content>
                            <v-stepper-content step="2">
                                <v-row>
                                    <v-col cols="12" md="12" class="pt-0">
                                        <Separator
                                            :text="
                                                $t('cars:carExtraInformation')
                                            "
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="4">
                                        <v-menu
                                            v-model="dateOfFirstRegistration"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                        >
                                            <template
                                                v-slot:activator="{
                                                    on,
                                                    attrs,
                                                }"
                                            >
                                                <v-text-field
                                                    data-cy-cars="dateOfFirstRegistration"
                                                    v-model="
                                                        getCarModal.dateOfFirstRegistration
                                                    "
                                                    :error-messages="
                                                        getCarError.stepTwo
                                                            .fields
                                                            .dateOfFirstRegistration
                                                    "
                                                    clearable
                                                    @click:clear="
                                                        getCarModal.dateOfFirstRegistration = null
                                                    "
                                                    :label="`${$t(
                                                        'cars:dateOfFirstRegistration'
                                                    )}*`"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                data-cy="datePicker"
                                                v-model="
                                                    getCarModal.dateOfFirstRegistration
                                                "
                                                @input="
                                                    dateOfFirstRegistration = false
                                                "
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>

                                    <v-col cols="4">
                                        <v-menu
                                            v-model="insuranceUpTo"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                        >
                                            <template
                                                v-slot:activator="{
                                                    on,
                                                    attrs,
                                                }"
                                            >
                                                <v-text-field
                                                    data-cy-cars="insuranceUpTo"
                                                    v-model="
                                                        getCarModal.insuranceUpTo
                                                    "
                                                    :error-messages="
                                                        getCarError.stepTwo
                                                            .fields
                                                            .insuranceUpTo
                                                    "
                                                    clearable
                                                    @click:clear="
                                                        getCarModal.insuranceUpTo = null
                                                    "
                                                    :label="`${$t(
                                                        'cars:insuranceUpTo'
                                                    )}*`"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                data-cy="datePicker"
                                                v-model="
                                                    getCarModal.insuranceUpTo
                                                "
                                                @input="insuranceUpTo = false"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-menu
                                            v-model="dateOfTheNextInspection"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                        >
                                            <template
                                                v-slot:activator="{
                                                    on,
                                                    attrs,
                                                }"
                                            >
                                                <v-text-field
                                                    data-cy-cars="dateOfTheNextInspection"
                                                    v-model="
                                                        getCarModal.dateOfTheNextInspection
                                                    "
                                                    :error-messages="
                                                        getCarError.stepTwo
                                                            .fields
                                                            .dateOfTheNextInspection
                                                    "
                                                    clearable
                                                    @click:clear="
                                                        getCarModal.dateOfTheNextInspection = null
                                                    "
                                                    :label="`${$t(
                                                        'cars:dateOfTheNextInspection'
                                                    )}*`"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                data-cy="datePicker"
                                                v-model="
                                                    getCarModal.dateOfTheNextInspection
                                                "
                                                @input="
                                                    dateOfTheNextInspection = false
                                                "
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="4">
                                        <v-menu
                                            v-model="warrantyUntil"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                        >
                                            <template
                                                v-slot:activator="{
                                                    on,
                                                    attrs,
                                                }"
                                            >
                                                <v-text-field
                                                    data-cy-cars="warrantyUntil"
                                                    v-model="
                                                        getCarModal.warrantyUntil
                                                    "
                                                    :error-messages="
                                                        getCarError.stepTwo
                                                            .fields
                                                            .warrantyUntil
                                                    "
                                                    clearable
                                                    @click:clear="
                                                        getCarModal.warrantyUntil = null
                                                    "
                                                    :label="`${$t(
                                                        'cars:warrantyUntil'
                                                    )}*`"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                data-cy="datePicker"
                                                v-model="
                                                    getCarModal.warrantyUntil
                                                "
                                                @input="warrantyUntil = false"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>

                                    <v-col cols="4">
                                        <v-text-field
                                            data-cy-cars="yearOfProduction"
                                            v-model="
                                                getCarModal.yearOfProduction
                                            "
                                            :error-messages="
                                                getCarError.stepTwo.fields
                                                    .yearOfProduction
                                            "
                                            :label="`${$t(
                                                'cars:yearOfProduction'
                                            )}*`"
                                            @keyup="
                                                checkNumber(
                                                    $event,
                                                    'yearOfProduction'
                                                )
                                            "
                                            prepend-icon="mdi-calendar"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="2">
                                        <v-text-field
                                            data-cy-cars="tireSize"
                                            v-model="getCarModal.tireSize"
                                            :label="$t('cars:tireSize')"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-select
                                            data-cy-cars="property"
                                            :items="propertyValue"
                                            v-model="getCarModal.property"
                                            :label="$t('cars:property')"
                                            item-value="field"
                                        >
                                            <template v-slot:item="data">
                                                <span
                                                    v-if="
                                                        data.item
                                                            .backgroundColor
                                                    "
                                                >
                                                    <v-chip
                                                        class="mx-2"
                                                        :color="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                        small
                                                        label
                                                        :text-color="
                                                            data.item.textColor
                                                                ? data.item
                                                                      .textColor
                                                                : '#4d4d4d'
                                                        "
                                                    >
                                                        {{ data.item.field }}
                                                    </v-chip>
                                                </span>
                                                <span
                                                    class="truncate-list"
                                                    v-else
                                                >
                                                    {{ data.item.field }}
                                                </span>
                                            </template>
                                            <template v-slot:selection="data">
                                                <span
                                                    v-if="
                                                        data.item
                                                            .backgroundColor
                                                    "
                                                >
                                                    <v-chip
                                                        class="mx-2"
                                                        :color="
                                                            data.item
                                                                .backgroundColor
                                                        "
                                                        small
                                                        label
                                                        :text-color="
                                                            data.item.textColor
                                                                ? data.item
                                                                      .textColor
                                                                : '#4d4d4d'
                                                        "
                                                    >
                                                        {{ data.item.field }}
                                                    </v-chip>
                                                </span>
                                                <span
                                                    class="truncate-list"
                                                    v-else
                                                >
                                                    {{ data.item.field }}
                                                </span>
                                            </template>
                                        </v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-textarea
                                            data-cy-cars="description"
                                            no-resize
                                            rows="1"
                                            v-model="getCarModal.description"
                                            :label="$t('cars:description')"
                                        ></v-textarea>
                                    </v-col> </v-row
                            ></v-stepper-content>
                        </v-stepper-items>
                    </template>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    data() {
        return {
            date: '',
            dateOfFirstRegistration: '',
            insuranceUpTo: '',
            yearOfProduction: '',
            warrantyUntil: '',
            dateOfTheNextInspection: '',
            property: [
                { text: this.$t('cars:personal'), value: 'personal' },
                { text: this.$t('cars:leasing'), value: 'leasing' },
                { text: this.$t('cars:credit'), value: 'credit' },
                { text: this.$t('cars:rent'), value: 'rent' },
                { text: this.$t('cars:borrowed'), value: 'borrowed' },
            ],
            // typeOfCar: [
            //     { text: this.$t('cars:personal'), value: 'personal' },
            //     { text: this.$t('cars:deliveryAbove'), value: 'deliveryAbove' },
            //     { text: this.$t('cars:deliveryBelow'), value: 'deliveryBelow' },
            //     { text: this.$t('cars:tir'), value: 'tir' },
            //     { text: this.$t('cars:other'), value: 'other' },
            // ],
            step: 1,
            list: [
                { color: 'white', size: 'XXL' },
                { color: 'red', size: 'XL' },
                { color: 'black', size: 'M' },
            ],

            editable: true,
        }
    },
    methods: {
        checkNumber(e, field) {
            if (e.target.value.length !== 4)
                return (this.getCarError.stepTwo.fields[field] = this.$t(
                    'cars:wrongYear'
                ))
            let convert = Number(e.target.value)
            if (!convert)
                this.getCarError.stepTwo.fields[field] = this.$t(
                    'cars:wrongYear'
                )
            else this.getCarError.stepTwo.fields[field] = ''
        },
    },

    computed: {
        ...mapGetters([
            'getCarModal',
            'getCarStep',
            'getCarError',
            'getUsersList',
            'getRegistryDictionaries',
        ]),
        drivers: {
            get() {
                return this.getUsersList.sort((a, b) =>
                    a.name > b.name ? 1 : -1
                )
            },
        },
        propertyValue: {
            get() {
                if (this.getRegistryDictionaries.property)
                    return this.getRegistryDictionaries.property.sort((a, b) =>
                        a.field > b.field ? 1 : -1
                    )
                else return []
            },
        },
        models: {
            get() {
                if (this.getRegistryDictionaries.model)
                    return this.getRegistryDictionaries.model.sort((a, b) =>
                        a.field > b.field ? 1 : -1
                    )
                else return []
            },
        },
        typeOfCar: {
            get() {
                if (this.getRegistryDictionaries.typeOfCar)
                    return this.getRegistryDictionaries.typeOfCar.sort((a, b) =>
                        a.field > b.field ? 1 : -1
                    )
                else return []
            },
        },
    },
}
</script>
