var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-stepper',{staticClass:"elevation-0",model:{value:(_vm.getCarStep.step),callback:function ($$v) {_vm.$set(_vm.getCarStep, "step", $$v)},expression:"getCarStep.step"}},[[_c('v-stepper-header',{staticClass:"elevation-0 stepperHeader"},[_c('v-divider'),_c('v-stepper-step',{attrs:{"rules":[() => _vm.getCarError.stepOne.validation],"editable":"","step":"1"}},[_vm._v(_vm._s(_vm.$t('cars:carInformation')))]),_c('v-divider'),_c('v-stepper-step',{attrs:{"rules":[() => _vm.getCarError.stepTwo.validation],"editable":"","step":"2"}},[_vm._v(_vm._s(_vm.$t('cars:carExtraInformation')))]),_c('v-divider')],1),_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t('cars:carInformation')}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"data-cy-cars":"brandAndModel","items":_vm.models,"error-messages":_vm.getCarError.stepOne.fields.model,"label":`${_vm.$t('cars:model')}*`,"item-value":"field"},scopedSlots:_vm._u([{key:"item",fn:function(data){return [(
                                                    data.item
                                                        .backgroundColor
                                                )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                            .backgroundColor,"small":"","label":"","text-color":data.item.textColor
                                                            ? data.item
                                                                  .textColor
                                                            : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}},{key:"selection",fn:function(data){return [(
                                                    data.item
                                                        .backgroundColor
                                                )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                            .backgroundColor,"small":"","label":"","text-color":data.item.textColor
                                                            ? data.item
                                                                  .textColor
                                                            : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}}]),model:{value:(_vm.getCarModal.model),callback:function ($$v) {_vm.$set(_vm.getCarModal, "model", $$v)},expression:"getCarModal.model"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"data-cy-cars":"registrationNumber","error-messages":_vm.getCarError.stepOne.fields
                                                .registrationNumber,"label":`${_vm.$t(
                                            'cars:registrationNumber'
                                        )}*`},model:{value:(
                                            _vm.getCarModal.registrationNumber
                                        ),callback:function ($$v) {_vm.$set(_vm.getCarModal, "registrationNumber", $$v)},expression:"\n                                            getCarModal.registrationNumber\n                                        "}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"data-cy-cars":"typeOfCar","items":_vm.typeOfCar,"label":_vm.$t('cars:typeOfCar'),"item-value":"field"},scopedSlots:_vm._u([{key:"item",fn:function(data){return [(
                                                    data.item
                                                        .backgroundColor
                                                )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                            .backgroundColor,"small":"","label":"","text-color":data.item.textColor
                                                            ? data.item
                                                                  .textColor
                                                            : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}},{key:"selection",fn:function(data){return [(
                                                    data.item
                                                        .backgroundColor
                                                )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                            .backgroundColor,"small":"","label":"","text-color":data.item.textColor
                                                            ? data.item
                                                                  .textColor
                                                            : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}}]),model:{value:(_vm.getCarModal.typeOfCar),callback:function ($$v) {_vm.$set(_vm.getCarModal, "typeOfCar", $$v)},expression:"getCarModal.typeOfCar"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"data-cy-cars":"registrationIDNumber","error-messages":_vm.getCarError.stepOne.fields
                                                .registrationIDNumber,"label":`${_vm.$t(
                                            'cars:registrationIDNumber'
                                        )}*`},model:{value:(
                                            _vm.getCarModal.registrationIDNumber
                                        ),callback:function ($$v) {_vm.$set(_vm.getCarModal, "registrationIDNumber", $$v)},expression:"\n                                            getCarModal.registrationIDNumber\n                                        "}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"data-cy-cars":"vin","error-messages":_vm.getCarError.stepOne.fields.vin,"label":`${_vm.$t('cars:vin')}*`},model:{value:(_vm.getCarModal.vin),callback:function ($$v) {_vm.$set(_vm.getCarModal, "vin", $$v)},expression:"getCarModal.vin"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4","offset":"8"}},[_c('v-select',{attrs:{"data-cy-cars":"driver","items":_vm.drivers,"item-value":"_id","label":_vm.$t('cars:driver')},scopedSlots:_vm._u([{key:"selection",fn:function({
                                                item,
                                            }){return [_c('span',[_vm._v(_vm._s(item.name)+" "+_vm._s(item.lastname))])]}},{key:"item",fn:function(data){return [_c('span',[_vm._v(_vm._s(data.item.name)+" "+_vm._s(data.item.lastname))])]}}]),model:{value:(_vm.getCarModal.driver),callback:function ($$v) {_vm.$set(_vm.getCarModal, "driver", $$v)},expression:"getCarModal.driver"}})],1)],1)],1),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t('cars:carExtraInformation')}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function({
                                                on,
                                                attrs,
                                            }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"data-cy-cars":"dateOfFirstRegistration","error-messages":_vm.getCarError.stepTwo
                                                        .fields
                                                        .dateOfFirstRegistration,"clearable":"","label":`${_vm.$t(
                                                    'cars:dateOfFirstRegistration'
                                                )}*`,"prepend-icon":"mdi-calendar","readonly":""},on:{"click:clear":function($event){_vm.getCarModal.dateOfFirstRegistration = null}},model:{value:(
                                                    _vm.getCarModal.dateOfFirstRegistration
                                                ),callback:function ($$v) {_vm.$set(_vm.getCarModal, "dateOfFirstRegistration", $$v)},expression:"\n                                                    getCarModal.dateOfFirstRegistration\n                                                "}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateOfFirstRegistration),callback:function ($$v) {_vm.dateOfFirstRegistration=$$v},expression:"dateOfFirstRegistration"}},[_c('v-date-picker',{attrs:{"data-cy":"datePicker"},on:{"input":function($event){_vm.dateOfFirstRegistration = false}},model:{value:(
                                                _vm.getCarModal.dateOfFirstRegistration
                                            ),callback:function ($$v) {_vm.$set(_vm.getCarModal, "dateOfFirstRegistration", $$v)},expression:"\n                                                getCarModal.dateOfFirstRegistration\n                                            "}})],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function({
                                                on,
                                                attrs,
                                            }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"data-cy-cars":"insuranceUpTo","error-messages":_vm.getCarError.stepTwo
                                                        .fields
                                                        .insuranceUpTo,"clearable":"","label":`${_vm.$t(
                                                    'cars:insuranceUpTo'
                                                )}*`,"prepend-icon":"mdi-calendar","readonly":""},on:{"click:clear":function($event){_vm.getCarModal.insuranceUpTo = null}},model:{value:(
                                                    _vm.getCarModal.insuranceUpTo
                                                ),callback:function ($$v) {_vm.$set(_vm.getCarModal, "insuranceUpTo", $$v)},expression:"\n                                                    getCarModal.insuranceUpTo\n                                                "}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.insuranceUpTo),callback:function ($$v) {_vm.insuranceUpTo=$$v},expression:"insuranceUpTo"}},[_c('v-date-picker',{attrs:{"data-cy":"datePicker"},on:{"input":function($event){_vm.insuranceUpTo = false}},model:{value:(
                                                _vm.getCarModal.insuranceUpTo
                                            ),callback:function ($$v) {_vm.$set(_vm.getCarModal, "insuranceUpTo", $$v)},expression:"\n                                                getCarModal.insuranceUpTo\n                                            "}})],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function({
                                                on,
                                                attrs,
                                            }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"data-cy-cars":"dateOfTheNextInspection","error-messages":_vm.getCarError.stepTwo
                                                        .fields
                                                        .dateOfTheNextInspection,"clearable":"","label":`${_vm.$t(
                                                    'cars:dateOfTheNextInspection'
                                                )}*`,"prepend-icon":"mdi-calendar","readonly":""},on:{"click:clear":function($event){_vm.getCarModal.dateOfTheNextInspection = null}},model:{value:(
                                                    _vm.getCarModal.dateOfTheNextInspection
                                                ),callback:function ($$v) {_vm.$set(_vm.getCarModal, "dateOfTheNextInspection", $$v)},expression:"\n                                                    getCarModal.dateOfTheNextInspection\n                                                "}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateOfTheNextInspection),callback:function ($$v) {_vm.dateOfTheNextInspection=$$v},expression:"dateOfTheNextInspection"}},[_c('v-date-picker',{attrs:{"data-cy":"datePicker"},on:{"input":function($event){_vm.dateOfTheNextInspection = false}},model:{value:(
                                                _vm.getCarModal.dateOfTheNextInspection
                                            ),callback:function ($$v) {_vm.$set(_vm.getCarModal, "dateOfTheNextInspection", $$v)},expression:"\n                                                getCarModal.dateOfTheNextInspection\n                                            "}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function({
                                                on,
                                                attrs,
                                            }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"data-cy-cars":"warrantyUntil","error-messages":_vm.getCarError.stepTwo
                                                        .fields
                                                        .warrantyUntil,"clearable":"","label":`${_vm.$t(
                                                    'cars:warrantyUntil'
                                                )}*`,"prepend-icon":"mdi-calendar","readonly":""},on:{"click:clear":function($event){_vm.getCarModal.warrantyUntil = null}},model:{value:(
                                                    _vm.getCarModal.warrantyUntil
                                                ),callback:function ($$v) {_vm.$set(_vm.getCarModal, "warrantyUntil", $$v)},expression:"\n                                                    getCarModal.warrantyUntil\n                                                "}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.warrantyUntil),callback:function ($$v) {_vm.warrantyUntil=$$v},expression:"warrantyUntil"}},[_c('v-date-picker',{attrs:{"data-cy":"datePicker"},on:{"input":function($event){_vm.warrantyUntil = false}},model:{value:(
                                                _vm.getCarModal.warrantyUntil
                                            ),callback:function ($$v) {_vm.$set(_vm.getCarModal, "warrantyUntil", $$v)},expression:"\n                                                getCarModal.warrantyUntil\n                                            "}})],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"data-cy-cars":"yearOfProduction","error-messages":_vm.getCarError.stepTwo.fields
                                                .yearOfProduction,"label":`${_vm.$t(
                                            'cars:yearOfProduction'
                                        )}*`,"prepend-icon":"mdi-calendar"},on:{"keyup":function($event){return _vm.checkNumber(
                                                $event,
                                                'yearOfProduction'
                                            )}},model:{value:(
                                            _vm.getCarModal.yearOfProduction
                                        ),callback:function ($$v) {_vm.$set(_vm.getCarModal, "yearOfProduction", $$v)},expression:"\n                                            getCarModal.yearOfProduction\n                                        "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"data-cy-cars":"tireSize","label":_vm.$t('cars:tireSize')},model:{value:(_vm.getCarModal.tireSize),callback:function ($$v) {_vm.$set(_vm.getCarModal, "tireSize", $$v)},expression:"getCarModal.tireSize"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"data-cy-cars":"property","items":_vm.propertyValue,"label":_vm.$t('cars:property'),"item-value":"field"},scopedSlots:_vm._u([{key:"item",fn:function(data){return [(
                                                    data.item
                                                        .backgroundColor
                                                )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                            .backgroundColor,"small":"","label":"","text-color":data.item.textColor
                                                            ? data.item
                                                                  .textColor
                                                            : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}},{key:"selection",fn:function(data){return [(
                                                    data.item
                                                        .backgroundColor
                                                )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                            .backgroundColor,"small":"","label":"","text-color":data.item.textColor
                                                            ? data.item
                                                                  .textColor
                                                            : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}}]),model:{value:(_vm.getCarModal.property),callback:function ($$v) {_vm.$set(_vm.getCarModal, "property", $$v)},expression:"getCarModal.property"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"data-cy-cars":"description","no-resize":"","rows":"1","label":_vm.$t('cars:description')},model:{value:(_vm.getCarModal.description),callback:function ($$v) {_vm.$set(_vm.getCarModal, "description", $$v)},expression:"getCarModal.description"}})],1)],1)],1)],1)]],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }