<template>
    <v-container>
        <Header
            :registryStatus="getCarStatuses"
            :recordIndex="getCarDetails.index"
            :registryTitle="getCarsRegistry.name"
            :registryUrl="'cars'"
            :actualStatus="getCarDetails.status"
            @status-changed="changeStatus"
            :avatarData="getCarDetails.createdBy"
        />
        <Main class="p-relative">
            <div slot="information">
                <Information />
                <Modal
                    :title="$t('cars:editionCar')"
                    :open="open"
                    :height="'520'"
                    v-on:close="closeModal()"
                >
                    <AEContent slot="AEContent" />
                    <Buttons
                        @closeModal="open = false"
                        :action="'edit'"
                        slot="buttons"
                    />
                </Modal>
                <div class="d-flex justify-end buttons--details">
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                fab
                                dark
                                v-on="on"
                                v-if="edit()"
                                small
                                @click="editCar()"
                                class="buttons--add margin--1"
                            >
                                <v-icon small>mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('cars:editCar') }}</span>
                    </v-tooltip>
                </div>
            </div>
        </Main>
    </v-container>
</template>
<script>
import store from './../../../store/index'
import Information from './../../../components//Registries/Cars/Details/Information'
import Buttons from './../../../components//Registries/Cars/Modal/Buttons'
import AEContent from './../../../components//Registries/Cars/Modal/Content'
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    i18nOptions: { namespaces: ['global'] },
    components: { Information, Buttons, AEContent },
    data() {
        return {
            open: false,
        }
    },
    computed: {
        ...mapGetters([
            'getCarStatuses',
            'getCarDetails',
            'getCarsRegistry',
            'getCarStep',
        ]),
    },
    methods: {
        ...mapMutations(['clearCarErrors', 'setCarModal']),
        ...mapActions(['fetchCar', 'updateCarStatus', 'fetchUsers']),
        async editCar() {
            await void this.fetchUsers({
                select: 'name lastname mail status',
                where: {
                    isUser: true,
                    'status.state': 'ACTIVE',
                    isDeleted: false,
                },
            })
            this.open = true
            this.clearCarErrors()
            this.setCarModal()
        },
        edit() {
            return this.checkPermissions('UPDATE')
        },
        changeStatus(status) {
            this.updateCarStatus({
                status,
                id: this.getCarDetails._id,
            })
        },
        closeModal() {
            this.clearCarErrors()
            this.open = false
            this.getCarStep.step = 1
        },
    },
    beforeRouteEnter(to, from, next) {
        store.dispatch('fetchCar', { id: to.params.id, next })
    },
}
</script>
