<template>
    <v-container>
        <v-row class="details__card">
            <v-col cols="3" xl="3" lg="3" md="5" sm="12" class="pa-5">
                <v-row>
                    <v-col cols="12" class="d-flex flex-column">
                        <p
                            data-cy="brandAndModel"
                            class="font-weight-bold d-flex flex-column headline pa-0 ma-0"
                        >
                            {{ getCarDetails.model }}
                        </p>
                        <span data-cy="registrationNumber" class="mb-5">{{
                            getCarDetails.registrationNumber
                        }}</span>
                        <span data-cy="driver"
                            ><b>{{ `${$t('cars:driver')}` }}:</b>
                            {{ $get(getCarDetails.driver, 'name', '') }}
                            {{
                                $get(getCarDetails.driver, 'lastname', '')
                            }}</span
                        >
                        <span class="mt-5" data-cy="insuranceUpTo"
                            ><b>{{ `${$t('cars:insuranceUpTo')}` }}:</b>
                            {{
                                $moment(getCarDetails.insuranceUpTo).format(
                                    `DD-MM-YYYY`
                                ) || $t('global:empty')
                            }}</span
                        >
                        <span
                            ><b data-cy="dateOfTheNextInspection"
                                >{{
                                    `${$t('cars:dateOfTheNextInspection')}`
                                }}:</b
                            >
                            {{
                                $moment(
                                    getCarDetails.dateOfTheNextInspection
                                ).format(`DD-MM-YYYY`) || $t('global:empty')
                            }}
                        </span>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="6" xl="6" lg="8" md="12" sm="12" class="pa-5">
                <v-row>
                    <v-col cols="12" md="12" class="pt-0">
                        <Separator :text="$t('cars:carInformation')" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3"
                        ><v-text-field
                            data-cy-details="brandAndModel"
                            readonly
                            :value="getCarDetails.model"
                            :label="$t('cars:model')"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="3"
                        ><v-text-field
                            data-cy-details="typeOfCar"
                            readonly
                            :value="$t(`cars:${getCarDetails.typeOfCar}`)"
                            :label="$t('cars:typeOfCar')"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="2"
                        ><v-text-field
                            data-cy-details="registrationNumber"
                            readonly
                            :value="getCarDetails.registrationNumber"
                            :label="$t('cars:registrationNumber')"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12" class="pt-0">
                        <Separator :text="$t('cars:carExtraInformation')" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">
                        <v-text-field
                            data-cy-details="dateOfFirstRegistration"
                            readonly
                            :value="
                                getCarDetails.dateOfFirstRegistration
                                    ? $moment(
                                          getCarDetails.dateOfFirstRegistration
                                      ).format('YYYY-MM-DD')
                                    : ''
                            "
                            :label="$t('cars:dateOfFirstRegistration')"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field
                            data-cy-details="registrationIDNumber"
                            readonly
                            :value="getCarDetails.registrationIDNumber"
                            :label="$t('cars:registrationIDNumber')"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="3">
                        <v-text-field
                            data-cy-details="insuranceUpTo"
                            readonly
                            :value="
                                getCarDetails.insuranceUpTo
                                    ? $moment(
                                          getCarDetails.insuranceUpTo
                                      ).format('YYYY-MM-DD')
                                    : ''
                            "
                            :label="$t('cars:insuranceUpTo')"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="2">
                        <v-text-field
                            data-cy-details="dateOfTheNextInspection"
                            readonly
                            :value="
                                getCarDetails.dateOfTheNextInspection
                                    ? $moment(
                                          getCarDetails.dateOfTheNextInspection
                                      ).format('YYYY-MM-DD')
                                    : ''
                            "
                            :label="$t('cars:dateOfTheNextInspection')"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field
                            data-cy-details="vin"
                            readonly
                            :value="getCarDetails.vin"
                            :label="$t('cars:vin')"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="2">
                        <v-text-field
                            data-cy-details="yearOfProduction"
                            readonly
                            :value="
                                getCarDetails.yearOfProduction
                                    ? $moment(
                                          getCarDetails.yearOfProduction
                                      ).format('YYYY-MM-DD')
                                    : ''
                            "
                            :label="$t('cars:yearOfProduction')"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="2">
                        <v-text-field
                            data-cy-details="tireSize"
                            readonly
                            :value="getCarDetails.tireSize"
                            :label="$t('cars:tireSize')"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="2">
                        <v-text-field
                            data-cy-details="warrantyUntil"
                            readonly
                            :value="
                                getCarDetails.warrantyUntil
                                    ? $moment(
                                          getCarDetails.warrantyUntil
                                      ).format('YYYY-MM-DD')
                                    : ''
                            "
                            :label="$t('cars:warrantyUntil')"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4">
                        <v-text-field
                            data-cy-details="property"
                            readonly
                            :value="$t(`cars:${getCarDetails.property}`)"
                            :label="$t('cars:property')"
                        ></v-text-field
                    ></v-col>
                    <v-col cols="4">
                        <v-text-field
                            data-cy-details="driver"
                            readonly
                            :value="`${$get(
                                getCarDetails.driver,
                                'name',
                                ''
                            )} ${$get(getCarDetails.driver, 'lastname', '')}`"
                            :label="$t('cars:driver')"
                        ></v-text-field
                    ></v-col>
                </v-row>
                <v-row>
                    <v-col cols="5">
                        <v-text-field
                            style="z-index: 0;"
                            readonly
                            :value="
                                $get(getCarDetails, 'leasing.leasingNumber', '')
                            "
                            :label="$t('cars:leasingnumber')"
                        >
                            <template v-slot:append-outer>
                                <v-btn
                                    v-if="
                                        $get(
                                            getCarDetails,
                                            'leasing.leasingNumber'
                                        ) && checkPermissions()
                                    "
                                    icon
                                    small
                                    router
                                    @click="goTo"
                                    ><v-icon color="secondary" size="18"
                                        >mdi-arrow-right-thick</v-icon
                                    ></v-btn
                                >
                            </template></v-text-field
                        >
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-textarea
                            data-cy-details="description"
                            readonly
                            no-resize
                            rows="1"
                            auto-grow
                            :value="getCarDetails.description"
                            :label="$t('cars:description')"
                        ></v-textarea>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    i18nOptions: { namespaces: ['cars'] },
    computed: {
        ...mapGetters(['getCarDetails', 'getProfileDetails']),
    },
    methods: {
        goTo() {
            if (this.getCarDetails.leasing?._id) {
                this.$router.push('/leasings/' + this.getCarDetails.leasing._id)
            }
        },
        checkPermissions() {
            return (
                this.getProfileDetails.isAdmin ||
                this.getProfileDetails.isSuperAdmin ||
                this.getProfileDetails.role.registries
                    .find(e => e.url === 'leasings')
                    .operations.includes('READ')
            )
        },
    },
}
</script>
